// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h1 {\n  font-size: 3em;\n}\n\nh3 {\n  font-size: 1.5em;\n}\n\nh2 {\n  font-size: 2.25em;\n}\n\n.CMcSs {\n  line-height: 1.125;\n  font-weight: 300;\n  max-width: 100%;\n}\n\n.t2ExF {\n  font-weight: 600;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "CMcSs",
	"strong": "t2ExF"
};
module.exports = ___CSS_LOADER_EXPORT___;
