// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FQzQ6 {\n  max-width: 100%;\n}\n\n.i5LPt {\n  width: 9rem;\n}\n\n.kfJC1 {\n  width: 24rem;\n}\n\n.AUn6p {\n  object-fit: contain;\n}\n\n.FxN1a {\n  width: 3rem;\n  height: 3rem;\n  flex: 0 0 auto;\n  object-fit: cover;\n}\n\n.moYzS {\n  width: 100%;\n  height: auto;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "FQzQ6",
	"small": "i5LPt",
	"medium": "kfJC1",
	"fit": "AUn6p",
	"thumb": "FxN1a",
	"full": "moYzS"
};
module.exports = ___CSS_LOADER_EXPORT___;
