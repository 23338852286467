// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I6p5t {\n  font-weight: 600;\n  margin-bottom: 24px;\n  max-width: 100%;\n}\n\n.S_Ifu {\n  font-size: 4rem;\n  line-height: 1;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headline": "I6p5t",
	"medium": "S_Ifu"
};
module.exports = ___CSS_LOADER_EXPORT___;
